.Family {
  position: relative;
  top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Poiret One', serif;
}

.Family_Сell {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: black;
}

.PhotoFamily {
  background-color: #282c34;
  width: 90vw;
  height: 64vh;
  margin: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.Header {
    background-image: url('https://volsvik.com/photography/Familis.jpg');
}

.Description {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.Family .card {
  width: 90vw;
  margin: 4vh 0px;
}

/*=========================400=======================*/

@media (min-width: 400px) {

.PhotoFamily {
  width: 70vw;
  height: 64vh;
}

}

/*=========================1055=======================*/

@media (min-width: 1055px) {

.PhotoFamily {
  width: 47vw;
  height: 64vh;
}

.Description {
  width: 40vw;
  min-width: 500px;
}

}

