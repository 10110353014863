.Home_header {
    background-color: #282c34;
    height: 71vh;
    width: 74vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    background-image: url('https://volsvik.com/photography/selfi.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}


