.Contact-header {
  position: relative;
  min-height: 100vh;
  /* left: 5vw; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('https://volsvik.com/photography/selfi.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.socialNetwork {
  position: relative;
  /* left: 5vw; */
  bottom: 20vh;
  width: 34vw;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.socialNetwork a {
  text-decoration: none;
}
