.Galleri-menu {
  position: relative;
  display: grid;
  grid-template:
    'a' 1fr
    'b' 1fr
    'c' 1fr
    'd' 1fr / 1fr;

  grid-gap: 1vw;
  background: transparent;
  width: 90vw;
  /* top: 84vh; */
  /* left: 4vw; */
  font-size: calc((3vmax + 3vmin) / 2);
  color: #000000;
  z-index: 1;
  cursor: pointer;
}

.Long-Poto {
  width: calc(90vw - 7vw);
  height: calc((90vw - 7vw) / 1.5);
  margin: 0px 1vw;
  overflow: hidden;
  transition: 2s;
}

.Long-Poto-Start {
  width: 0;
  height: 0;
  margin: 0px 1vw;
  overflow: hidden;
}

.Long-Poto img {
  height: 100%;
  object-fit: contain;
  transition: 1s;
}
.Long-Poto img:hover {
  transform: scale(1.4);
}

.Long-Poto01 {
  grid-area: a;
}

.Long-Poto02 {
  grid-area: b;
}

.Long-Poto03 {
  grid-area: c;
}

.Long-Poto04 {
  grid-area: d;
}

.Wedding-Poto01 {
  grid-area: a;
}

.Wedding-Poto02 {
  grid-area: b;
}

.Wedding-Poto03 {
  grid-area: c;
}

.Wedding-Poto04 {
  /* width: 100%;
  margin: 0px;
  background-image: url(../../assets/photo1004.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; */
  grid-area: d;
}

.p-dialog {
  background-color: #fff;
  /* font-size: 24px; */
  /* font-family: 'Poiret One', serif; */
}
.p-dialog-title {
  margin: 0px 40px;
  font-size: 24px;
  /* 'Cormorant Garamond', 'Amatic SC', 'Poiret One', 'Comforter', 'Caveat', serif; */
  font-family: 'Poiret One', serif;
}
/* .p-dialog .p-dialog-header-icons */

.p-dialog-header-close-icon {
  width: 40px;
  height: 40px;
  /* color: #FFFFFF; */
}
.p-dialog-resizable {
  background-color: #000000;
  /* padding: 0; */
  /* opacity: 0.7; */
}

.card {
  position: relative;
  /* height: 100vh; */
}

.Wedding_Page .card {
  width: 90vw;
  margin: 4vh 0px;
}

.Wedding_Сell .card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.p-sidebar-content .MenuNew {
  left: 0px;
}

.p-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .p-dialog-content img {
  opacity: 1;
  z-index: 20000;
} */

/* .PhotoView-Slider__toolbarIcon svg{
    width: 40px;
  height: 40px;
} */
/* .PhotoView-Slider__ArrowRight svg{
  padding: 0px;
    width: 80px;
  height: 80px;
} */
/* .p-galleria-item-nav,
.p-galleria-close svg {
  width: 40px;
  height: 40px;
  color: #ffffff;
} */

/*=========================400=======================*/

@media (min-width: 400px) {
  .Galleri-menu {
    /* position: relative; */
    display: grid;
    grid-template:
      'a b' 1fr
      'c d' 1fr / 1fr 1fr;

    grid-gap: 1vw;
    background: transparent;
    width: 90vw;
    /* top: 70vh; */
    /* left: 4vw; */
    font-size: calc((3vmax + 3vmin) / 2);
    color: #000000;
    z-index: 1;
    cursor: pointer;
  }

  .Long-Poto {
    width: calc((90vw - 7vw) / 2);
    height: calc((90vw - 7vw) / 3);
    margin: 0px;
    overflow: hidden;
  }
}

/*=========================800=======================*/

@media (min-width: 800px) {
  .Galleri-menu {
    /* position: relative; */
    display: grid;
    grid-template: 'a b c d' 1fr / 1fr 1fr 1fr 1fr;

    grid-gap: 1vw;
    background: transparent;
    width: 90vw;
    /* top: 70vh; */
    /* left: 4vw; */
    font-size: calc((3vmax + 3vmin) / 2);
    color: #000000;
    z-index: 1;
    cursor: pointer;
  }

  .Long-Poto {
    width: 20vw;
    height: 13vw;
    margin: 0px;
    overflow: hidden;
  }
}
