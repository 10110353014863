.NoPage-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(235, 128, 20);
    background-image: url('https://volsvik.com/photography/NoPage.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}