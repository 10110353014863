@import url('https://fonts.googleapis.com/css2?family=Inika:wght@400;700&family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comforter&family=Comforter+Brush&family=Marck+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.language {
  position: relative;
  /* width: 3.4vw;
  height: 3.4vw;
  top: 4vw;
  right: 4vw; */
  left: 2vw;
  padding: 0px 0px 1vw 0px;
  /* font-size: 2.7vw; */
  color: #000000;
  z-index: 3;
  cursor: pointer;
}

.language:hover {
  color: #fd6406;
}

a {
  /* 'Cormorant Garamond', 'Amatic SC', 'Poiret One', 'Comforter', 'Caveat', serif; */
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
  font-style: Italic;
  font-size: calc((4.4vmax + 4.4vmin) / 1.2);
  /* color: #fd6406; */
  color: #535353;
}

a:hover {
  /* font-weight: 700; */
  /* font-size: calc((4.4vmax + 4.4vmin) / 2); */
  color: #fd6406;
  transition: 0.5s;
}

.menuBig {
  display: none;
}

.menu .card .Button {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  top: 0px;
  right: 0px;
  background: transparent;
  border: 0;
}

.ButtonBurger {
  width: 20px;
  border: 1px solid;
  margin: 2px 0px;
}

.Button span {
  color: #000000;
  font-size: 10px;
}

.SidebarBurger {
  width: 34vw;
  background: #ffffff;
}

.SidebarBurger .MenuNew{
    width: 24vw;
  left: 2vw;
}

/* ===================menu01====================== */
.MenuNew {
  position: relative;
  top: 10vh;
  left: 14vw;
}

.MenuNew span {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
  font-style: Italic;
  font-size: calc((4.4vmax + 4.4vmin) / 2);
  color: #535353;
  cursor: pointer;
  transition: 0.5;
}

menu {
  position: relative;
  left: 2vw;
  width: 12vw;
  margin: 0px;
  padding: 0px;
}

.MenuNew a {
  display: block;
  position: relative;
  left: 1vmin;
  text-decoration: none;
  padding: 0px 0px 0vw 0px;
  font-size: calc((4.4vmax + 4.4vmin) / 2);
  /* color: #000000; */
  z-index: 3;
  cursor: pointer;
}

.MenuNew span a {
  left: 0vmin;
}

.linkOn {
  display: block;
}

.linkOff {
  display: none;
}

li {
  list-style-type: none;
}

.linkOn a:hover,
.MenuNew span a:hover,
.MenuNew span:hover {
  font-weight: 400;
  font-size: calc((4.4vmax + 4.4vmin) / 2);
  color: #fd6406;
  transition: 0.5;
}

/*=========================800=======================*/

@media (min-width: 800px) {
  .menu .card .Button {
    display: none;
  }

  .menuBig {
    display: flex;
  }
  
a {
  /* 'Cormorant Garamond', 'Amatic SC', 'Poiret One', 'Comforter', 'Caveat', serif; */
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
  font-style: Italic;
  font-size: calc((4.4vmax + 4.4vmin) / 2);
  /* color: #fd6406; */
  color: #535353;
}

}
