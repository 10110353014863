.Book-header {
  background-image: url('https://volsvik.com/photography/Books.jpg');
}

/* .Book-header h1{
position: fixed;
bottom: 10vh;
  font-family: 'Poiret One', serif;
} */

.Wedding_Book {
  position: relative;
  margin: 10vh 0px 2vh 0px;
  /* top: 10vh; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: black;
}

.Description {
  font-family: 'Poiret One', serif;
}
/* ======================================================== */
.Book {
  position: relative;
  top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Poiret One', serif;
}

.Book_Сell {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: black;
}

.PhotoBook {
  background-color: #282c34;
  width: 90vw;
  height: 64vh;
  margin: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url('https://volsvik.com/photography/BooksPage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Header {
  background-image: url('https://volsvik.com/photography/BooksPage.jpg');
}

.Description {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.DescriptionLong {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.Book .card {
  width: 90vw;
  margin: 4vh 0vw 4vh 4vw;
}

.Galleri-MenuBook {
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: black;
  z-index: 1;
  cursor: pointer;
}

.Long-Poto {
  margin: 1vh 1vw;
  overflow: hidden;
  transition: 2s;
}

.Long-Poto-Start {
  width: 0;
  height: 0;
  margin: 0px 1vw;
  overflow: hidden;
}

.Long-Poto img {
  height: 100%;
  object-fit: contain;
  transition: 1s;
}
.Long-Poto img:hover {
  transform: scale(1.4);
}

/*=========================400=======================*/

@media (min-width: 400px) {
  .PhotoBook {
    width: 70vw;
    height: 64vh;
  }
}

/*=========================1055=======================*/

@media (min-width: 1055px) {
  .PhotoBook {
    width: 47vw;
    height: 64vh;
  }

  .Description {
    width: 40vw;
    min-width: 500px;
  }
}
