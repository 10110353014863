/* .Start_Page {
  width: 90vw;
} */

.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 34vw;
  height: 34vh;
  top: 2vw;
  right: 2vw;
  font-size: calc((3vmax + 3vmin) / 2);
  color: #000000;
  z-index: 2;
  cursor: pointer;
}

.SelectedCantry {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 14vw;
  height: 14vh;
  top: 2vw;
  left: 7vw;
  font-size: calc((3vmax + 3vmin) / 2);
  color: #000000;
  z-index: 10;
  cursor: pointer;
}

.p-dropdown-items {
  font-size: calc((3vmax + 3vmin) / 2);
}
.p-dropdown-items li:hover {
  color: #fd6406;
}
.myName {
  position: relative;
  left: 2vmin;
  text-decoration: none;
  padding: 0px 0px 1vw 0px;
  font-style: normal;
  font-size: calc((3vmax + 3vmin) / 2);
  color: #000000;
  z-index: 3;
  cursor: pointer;
}

.myName:hover {
  font-size: calc((3vmax + 3vmin) / 2);
  color: #fd6406;
}

.Footer {
  background-color: #282c34;
  height: 17vh;
  margin: 10vh 0px 0vh 0px;
    padding: 2vh 0px 0vh 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Footer_Text {
  background-color: #282c34;
  height: 15vh;
  /* padding: 2vh 0px 0vh 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.Footer_Text a {
  font-size: calc(1px + 4vmin);
  color: white;
}

.Footer_Text p {
  margin: 0;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
  font-style: Italic;
  font-size: calc(1px + 4vmin);
  color: white;
}

/*=========================800=======================*/

@media (min-width: 800px) {
  /* .menu {
  display: flex;
} */

  /* .myName {
    left: 0px;
    padding: 0px 0px 1vw 0px;
    font-size: calc((2.7vmax + 2.7vmin)/2);
} */
}
