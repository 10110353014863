.Club-header {
  /* position: relative; */
  background-color: #FFFFFF;
  min-height: 100vh;
  /* left: 5vw; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  /* background-image: url('https://volsvik.com/photography/Club.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Club-Galleria {
  position: relative;
  left: 7vw;
  top: 14vh;
  width: 74vw;
  min-width: 180px;
}
